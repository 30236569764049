import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Skeleton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { FormParser } from '@src/design-system/forms/parsers';
import { GenericError } from 'design-system';
import {
  AdventureHeaderDocument,
  DefaultAdventureSettingsFragment,
  LinksFragment,
  UpdateAdventureSettingsDocument,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { TextField } from 'mui-rff';
import { RadioGroup } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { Radio } from '@mui/material';
import { FormControlLabel } from 'design-system';

type AdventureSettings = Omit<
  LinksFragment & DefaultAdventureSettingsFragment,
  '__typename'
>;

export const AdventureSettingsTab = () => {
  const { adventureId } = useRequiredParams(['adventureId']);

  const { data, loading, error } = useQuery(AdventureHeaderDocument, {
    variables: {
      id: adventureId,
    },
  });

  const [updateAdventureSettings] = useMutation(UpdateAdventureSettingsDocument);
  const { success, error: errorToast } = useToast();
  const { safeMutation } = useSafeMutation();

  const onSubmit = async (fields: AdventureSettings) => {
    const input = { id: adventureId, ...fields };
    await safeMutation(
      updateAdventureSettings({
        variables: {
          input,
        },
      }),
      {
        onSuccess: () => success('Settings updated'),
        onError: () => errorToast('Settings failed to update'),
      },
    );
  };

  if (loading) {
    return <Skeleton variant="text" width="25%" sx={{ fontSize: '30px' }} />;
  }

  if (error) {
    return <GenericError error={error} />;
  }

  if (!data) {
    throw new Error('Data not loaded');
  }

  const { adventure } = data;
  const initialValues: AdventureSettings = {
    faqUrl: adventure.faqUrl,
    bookingsDashboardUrl: adventure.bookingsDashboardUrl,
    feedbackUrl: adventure.feedbackUrl,
    dietaryRequirementsUrl: adventure.dietaryRequirementsUrl,
    defaultCapacity: adventure.defaultCapacity,
    defaultMinToGuarantee: adventure.defaultMinToGuarantee,
    defaultMaxEarlyBirds: adventure.defaultMaxEarlyBirds,
    includeInTripFiller: adventure.includeInTripFiller ?? false,
  };
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, dirty }) => {
        return (
          <>
            <form onSubmit={(v) => void handleSubmit(v)}>
              <Stack direction="row" gap={12}>
                <Stack gap={4} sx={{ my: 4, width: '300px' }}>
                  <Typography variant="h2">Defaults</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="bodySingle">Include in Trip Filler</Typography>
                    <Field name="includeInTripFiller" type="boolean">
                      {({ input }) => (
                        <RadioGroup
                          {...input}
                          value={Boolean(input.value)}
                          onChange={(e) => input.onChange(e.target.value === 'true')}
                          sx={{
                            flexDirection: 'row',
                            gap: 0,
                            '& .MuiFormControlLabel-root': {
                              marginRight: 0,
                              marginLeft: 0,
                            },
                          }}
                        >
                          {[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                          ].map((option) => (
                            <FormControlLabel
                              key={option.label}
                              value={option.value}
                              control={<Radio size="small" />}
                              label={option.label}
                              sx={{ '& .MuiTypography-root': { fontSize: '14px' } }}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    </Field>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="bodySingle">Pax. Capacity</Typography>
                    <TextField
                      fullWidth={false}
                      required
                      autoComplete="off"
                      size="small"
                      name="defaultCapacity"
                      InputProps={{ sx: { width: '70px' } }}
                      fieldProps={{
                        parse: FormParser.parseNumber,
                      }}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="bodySingle">Min. Guarantee</Typography>
                    <TextField
                      fullWidth={false}
                      required
                      autoComplete="off"
                      size="small"
                      name="defaultMinToGuarantee"
                      InputProps={{ sx: { width: '70px' } }}
                      fieldProps={{
                        parse: FormParser.parseNumber,
                      }}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="bodySingle">Max. Early Birds</Typography>
                    <TextField
                      fullWidth={false}
                      autoComplete="off"
                      required
                      size="small"
                      name="defaultMaxEarlyBirds"
                      InputProps={{ sx: { width: '70px' } }}
                      fieldProps={{
                        parse: FormParser.parseNumber,
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack gap={4} sx={{ my: 4 }}>
                  <Box>
                    <Typography variant="h2">Links</Typography>
                    <Typography sx={{ color: 'principal.grey70' }} variant="captionPara">
                      To remove a link, leave the field blank and save.
                    </Typography>
                  </Box>
                  <TextField
                    label={`Bookings Dashboard URL`}
                    fullWidth
                    type="url"
                    autoComplete="off"
                    size="small"
                    sx={{ maxWidth: '400px' }}
                    name="bookingsDashboardUrl"
                    placeholder="https://flashpack.clicdata.com/..."
                  />
                  <TextField
                    label={`FAQ Page URL`}
                    fullWidth
                    type="url"
                    autoComplete="off"
                    size="small"
                    sx={{ maxWidth: '400px' }}
                    name="faqUrl"
                    placeholder="https://notion.so/flashpacktravel/..."
                  />
                  <TextField
                    label={`Feedback Dashboard URL`}
                    fullWidth
                    type="url"
                    autoComplete="off"
                    size="small"
                    sx={{ maxWidth: '400px' }}
                    name="feedbackUrl"
                  />
                  <TextField
                    label={`Dietary Requirements Document URL`}
                    fullWidth
                    type="url"
                    autoComplete="off"
                    size="small"
                    sx={{ maxWidth: '400px' }}
                    name="dietaryRequirementsUrl"
                  />
                </Stack>
              </Stack>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={!dirty}
                loading={submitting}
                sx={{ width: '120px', my: 2 }}
              >
                Save
              </LoadingButton>
            </form>
          </>
        );
      }}
    />
  );
};
