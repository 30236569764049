import React, { FC, useState } from 'react';
import { DepartureStatus, UserRole } from '@flashpack/graphql';
import { Button, Menu, MenuItem } from '@mui/material';
import { DepartureStatusItem } from '@src/departures/departure-status/DepartureStatusItem';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { OpenMenu } from '@src/departures/departure-status/icons/DepartureStatusIcon';
import { StopPropagationWrapper } from '@src/design-system/StopPropagationWrapper';

interface PropTypes {
  onCancel: () => void;
  status?: DepartureStatus;
}

export const DepartureStatusMenu: FC<PropTypes> = (props) => {
  const { status, onCancel } = props;
  const { currentUser } = useAuthorization();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent) => {
    setAnchorEl(e.target as HTMLElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSuperUser = currentUser?.role == UserRole.Superuser;

  const isFlashpackUser =
    currentUser?.role == UserRole.Flashpack || currentUser?.role == UserRole.Superuser;

  const cancellableStatuses = [
    DepartureStatus.OnSale,
    DepartureStatus.Guaranteed,
    DepartureStatus.OnRequest,
    DepartureStatus.SoldOut,
  ];

  const canCancelDeparture =
    isSuperUser || (isFlashpackUser && (!status || cancellableStatuses.includes(status)));

  const handleCancel = () => {
    onCancel();
    handleClose();
  };

  return (
    <StopPropagationWrapper>
      {canCancelDeparture && (
        <Menu
          id="departure-status-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
        >
          <MenuItem key={DepartureStatus.Cancelled}>
            <DepartureStatusItem
              onClick={() => handleCancel()}
              status={DepartureStatus.Cancelled}
            />
          </MenuItem>
        </Menu>
      )}
      {status && (
        <DepartureStatusItem
          openIcon={canCancelDeparture}
          onClick={handleClick}
          status={status}
        />
      )}
      {!status && (
        <Button
          onClick={handleClick}
          variant="light-outlined"
          endIcon={<OpenMenu fill="white" />}
        >
          Change status
        </Button>
      )}
    </StopPropagationWrapper>
  );
};
